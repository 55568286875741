<template>
  <div id="home">
    <div id="header">
      <div class="w">
        <div class="menu-wrapper left">
          <ul>
            <li>
              <router-link to="home" :class="{}">首页</router-link>
            </li>
            <li>
              <router-link to="business">主营业务</router-link>
            </li>
            <li>
              <router-link to="rating">送评中心</router-link>
            </li>
          </ul>
        </div>
        <div id="banner">
          <img src="@/assets/img/home/blackstone.png" />
        </div>
        <div class="menu-wrapper right">
          <ul>
            <li>
              <router-link to="appraisal">评级查询</router-link>
            </li>
            <li>
              <router-link to="sale">售后服务</router-link>
            </li>
          </ul>
        </div>
        <div id="phone">
          <span>客服电话：17880280437</span>
        </div>
      </div>
    </div>
  </div>
  <router-view />
  <div id="footer">
    <div class="w">
      <div class="comp-info">
        <ul>
          <li style="font-weight: bold">客服联系方式</li>
          <li>客服微信号：hubei18881888</li>
          <li>客服手机号：17880280437</li>
        </ul>
        <ul>
          <li style="font-weight: bold">公司信息</li>
          <li>公司名称：重庆黑石艺术品鉴定有限公司</li>
          <li>公司电话：17880280437</li>
          <li>公司邮箱：1070500811@qq.com</li>
          <li>公司地址：重庆市渝中区中兴路熊猫古玩市场</li>
        </ul>
      </div>

      <div class="comp-code">
        <div>
          <img src="@/assets/img/home/code.png" />
          <span>微信公众号</span>
        </div>
        <div>
          <img src="@/assets/img/home/kefu.png" />
          <span>客服微信</span>
        </div>
      </div>
    </div>
    <div class="translations">
      <img src="@/assets/img/home/beian.png" />
      <a href="https://beian.miit.gov.cn" target="_blank">
        ICP备案号为：渝ICP备2022005705号-1
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    console.log(this.$route);
  },
};
</script>


<style lang="less" scoped>
#home {
  position: relative;
  z-index: 10;
  #header {
    height: 120px;
    background-color: #2c333c;
    //background: url("@/assets/img/home/top.png") no-repeat center;
    background-size: cover;
    .w {
      display: flex;
      height: 100%;
      position: relative;
      .menu-wrapper {
        width: 50%;
        position: absolute;
        bottom: 0px;
        &.right {
          right: 0px;
          ul {
            margin-left: 70px;
          }
        }
        &.left {
          ul {
            float: right;
            margin-right: 70px;
          }
        }
        ul {
          width: fit-content;

          justify-content: space-between;
          li {
            display: inline-block;
            margin: 0px 50px;
            a {
              display: block;
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              line-height: 60px;
              &.router-link-exact-active {
                color: #c19b76;
              }
            }
          }
        }
      }
      #banner {
        position: absolute;
        left: 50%;
        top: 76%;
        transform: translate(-50%, -50%);
        img {
          width: 186px;
          
        }
      }
      #phone {
        position: absolute;
        right: 0px;
        top: 35px;
        span {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
}
#footer {
  background-color: #2c333c;
  .w {
    display: flex;
    height: 270px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #3d454f;

    .comp-info {
      display: flex;
      ul {
        margin-right: 30px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fefefe;
        line-height: 22px;
        li {
          margin: 15px 0px;
        }
      }
    }
    .comp-code {
      display: flex;
      text-align: center;
      div {
        margin-left: 40px;
        img {
          width: 137px;
          height: 137px;
        }
      }
      span {
        margin-top: 10px;
        display: block;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fefefe;
        line-height: 22px;
      }
    }
  }
  .translations {
    width: 1200px;
    margin: auto;
    height: 80px;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #fefefe;
    img {
      margin-right: 20px;
    }
  }
}
</style>
