<template>
  <div id="main">
    <div class="banner">
      <img src="@/assets/img/business/banner.png" />
    </div>
    <div class="w">
      <div class="wrapper">
        <div v-for="item in businessList" :key="item.name">
          <img :src="item.img" />
          <p>{{ item.name }}</p>
        </div>
        <div
          style="width: 260px; height: 0px; padding: 0px; margin: 0px 20px"
        ></div>
        <div
          style="width: 260px; height: 0px; padding: 0px; margin: 0px 20px"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  setup() {
    const businessList = [
      { name: "铜元评级", img: require("@/assets/img/business/6.png") },
      { name: "银元评级", img: require("@/assets/img/business/1.png") },
      { name: "邮票评级", img: require("@/assets/img/business/2.png") },
      { name: "古钱币评级", img: require("@/assets/img/business/3.png") },
      { name: "纸钞评级", img: require("@/assets/img/business/5.png") },
      { name: "现代纪念品评级", img: require("@/assets/img/business/4.png") },
    ];
    return {
      businessList,
    };
  },
};
</script>

<style lang="less" scoped>
#main {
    
  background-color: #f7f2ef;
  z-index: 1;
  position: relative;
  .banner {
    padding-bottom: 19.6354%;
    height: 0px;
    img {
      position: relative;
      top: 0px;
      width: 100%;
      display: block;
    }
  }
  .w {
    .wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 30px 0px;
      div {
        padding: 10px;
        margin: 20px;
        background: #fff;
        img {
          width: 240px;
          height: 189px;
        }
        P {
          margin-top: 10px;
          text-align: center;
          font-size: 28px;
          font-weight: bold;
          color: #2a2a2a;
        }
      }
    }
  }
}
</style>

