import { createRouter, createWebHistory } from "vue-router"
import Home from '@/pages/Home'
import Rating from '@/pages/Rating'
import Business from '@/pages/Business'
import Appraisal from '@/pages/Appraisal'
import Sale from '@/pages/Sale'

const routes = [
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: '/home',
        name: "home",
        component: Home
    },
    {
        path: '/rating',
        name: "rating",
        component: Rating
    },
    {
        path: '/business',
        name: "business",
        component: Business
    },
    {
        path: '/appraisal',
        name: "appraisal",
        component: Appraisal
    },
    {
        path: '/sale',
        name: "sale",
        component: Sale
    },
]
export const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

export default router 