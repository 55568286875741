<template>
  <div id="main">
    <div class="banner">
      <img src="@/assets/img/home/home-big.png" />
    </div>
    <div class="w">
      <div class="wrapper">
        <div id="comp-info">
          <div id="info-header">
            <span>{{ ne.title }}</span>
            <!--span class="time">时间：2022-2-21</span-->
          </div>
          <div>
            <p v-for="(c, i) in ne.content" :key="i" v-html="c"></p>
            <!--p>2021年，黑石评级推出了“银币免费评级”活动。</p>
            <p>活动得到了数千位银币收藏爱好者的参与，广受好评。</p>
            <p>
              本月，我们又推出了首创的“保分赔付”，对评级负责，对评分负责，向人情分说不！并且接受广大用户的监督
              和建议。
            </p>
            <p>
              （活动详情点击（保粹）向“人情分”说不，首推保分赔付，再次领先行业
            </p-->
          </div>
        </div>
        <div id="more">
          <div class="more-title">
            <p>APPRECIATE TO SHOW</p>
            <p class="more-content">更多精彩<span>内容</span></p>
          </div>
          <div class="more-content">
            <img src="@/assets/img/home/more-img.png" />
            <div class="more-wrapper">
              <div
                class="more-item"
                v-for="(ne, index) in news"
                :key="ne.title"
              >
                <p @click="changeNe(index)">
                  {{ ne.title }}<span>{{ ne.time }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "Home",
  setup() {
    const news = ref([
      {
        title: "首家高赔付公司诞生",
        time: "2022/02/02",
        content: [
          "重庆黑石艺术品鉴定有限公司（英文简称：BSPG）成立于2022年3月11日。是国内少有的无代理、无分部的民间文物艺术品鉴定有限公司。剑指国际评级机构，整理国内评级乱象。一心在鉴定领域耕耘，为广大藏友提供高品质、高可靠、零误差的评级服务。",
        ],
      },
      {
        title: "黑石评级2022（共抗疫情）重磅评级活动",
        time: "2022/02/02",
        content: [
          "重庆黑石评级，为了进一步给藏友提供专业、精准、贴心的评级服务。经过与广大藏友交流探讨之后，特意在推出共抗疫情活动。目的是为为各中小主播减少评级费用压力，为各大藏友去评级费溢价空间。并承诺做到以下几点：严格把控评级质量、万无一失的真品入盒、即时赔付。活动详情如下：",
          "一、活动面向对象本次活动主要针对各大平台收藏类主播，活动参与者只需要提供相关平台直播后台截图即可报名参加。活动名额共计100个，原则上是在资格审核通过后，按照时间顺序先到先得。参加本活动者，可以优先参加下一年度的新活动。",
          "二、活动内容凭借主播身份，可享受2至5折评级优惠，具体优惠详情见下表：",
          "<table style='border:1px solid #a9a9a9'><thead><tr><td>月送评数量</td><td>折扣力度</td></tr></thead><tbody><tr><td>100枚以内</td><td>享受5折</td></tr><tr><td>100-200枚以内</td><td>享受4折优惠</td></tr><tr><td>200-500枚以内</td><td>享受3折优惠</td></tr><tr><td>500-1000枚以内</td><td>享受2.5折优惠</td></tr><tr><td>1000枚以上</td><td>享受2折优惠</td></tr></tbody></table>",
          "例:老王5月送评邮票280张，单张邮票评级费30。折扣后100张内享受五折，评级费就是15元，费用1500。100到200枚内，折扣后4折，评级费就是12元，费用为1200。剩下80张在200到500枚内，享受3折优惠，评级费就是9元，费用720。以上总费用为3420。对比不打折的费用8400，直接节约4980元。",
          "三、活动时间",
          "即日起至2022年12月31日截至。",
          "四、报名方式",
          "按照要求填写报表格，并添加客服微信二维码，把表格提交给客服进行审核。",
          "1.<报名表格><a href='http://bspg-sundry-01.yanbug.com/2022%E5%85%B1%E6%8A%97%E7%96%AB%E6%83%85%E6%B4%BB%E5%8A%A8%E6%8A%A5%E5%90%8D%E8%A1%A8%E6%A0%BC.docx'>下载</a>",
        ],
      },
    ]);
    const ne = ref(news.value[0]);
    const changeNe = function (index) {
      console.log(index);
      ne.value = news.value[index];
    };
    return { news, ne, changeNe };
  },
};
</script>

<style lang="less" scoped>
#main {
  background-color: #f7f2ef;
  z-index: 1;
  position: relative;
  .banner {
    padding-bottom: 36.718%;
    height: 0px;
    img {
      position: relative;
      top: 0px;
      width: 100%;
      display: block;
    }
  }
  .w {
    .wrapper {
      padding: 54px 0px 96px 0px;
      #comp-info {
        padding: 60px 60px;
        background-color: #fff;
        #info-header {
          border-bottom: 1px solid #2a2a2a1a;
        }
        span {
          font-size: 44px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #2a2a2a;
          margin-bottom: 15px;
          display: block;
          &.time {
            height: 20px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #b7824e;
            margin-bottom: 20px;
          }
        }
        p {
          margin: 10px 0px;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 32px;
          /deep/ table {
            border: 1px solid #a9a9a9;
            td {
              padding: 10px;
              border: 1px solid #a9a9a9;
            }
          }
        }
      }
      #more {
        margin-top: 50px;
        padding: 60px 60px;
        background-color: #fff;
        .more-title {
          p {
            font-size: 19px;
            font-family: Didot HTF-M16-;
            font-weight: 500;
            color: #222222;

            &.more-content {
              font-size: 46px;
              font-weight: bold;
              color: #b7824e;
              margin-top: 20px;

              span {
                font-size: 46px;
                font-weight: bold;
                color: #000;
              }
            }
          }
        }
        .more-content {
          margin-top: 40px;
          display: flex;
          img {
            width: 265px;
            height: 195px;
          }
          .more-wrapper {
            margin-left: 40px;
            .more-item {
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #2a2a2a;
              margin-bottom: 10px;
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #2a2a2a;
              p {
                cursor: pointer;
              }
              span {
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                display: inline-block;
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

