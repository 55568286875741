<template>
  <div id="main">
    <div class="banner">
      <img src="@/assets/img/rating/banner.png" />
      <div class="search-wrapper">
        <span>鉴定查询</span>
        <div class="search-bar">
          <img src="@/assets/img/rating/search.png" />
          <input
            v-model="keyWord"
            type="text"
            placeholder="请输入评级编号进行查询"
            @keyup.enter="search"
          />
          <div class="search" @click="search">搜索</div>
        </div>
      </div>
    </div>
    <div class="w">
      <div class="wrapper">
        <div class="list-bar">
          <ul>
            <li :class="{ active: currentMenu == 0 }" @click="currentMenu = 0">
              送评流程
            </li>
            <li :class="{ active: currentMenu == 1 }" @click="currentMenu = 1">
              评级标准
            </li>
            <li :class="{ active: currentMenu == 2 }" @click="currentMenu = 2">
              收费标准
            </li>
            <li :class="{ active: currentMenu == 3 }" @click="currentMenu = 3">
              赔付条约
            </li>
          </ul>
        </div>
      </div>
      <div v-show="currentMenu == 0">
        <div class="address">
          <p>在线送评地址</p>
          <div class="address-info">
            <p>重庆市渝中区中兴路熊猫古玩市场1-54</p>
            <p>联系人：黑石评级（段坤）</p>
            <p>电话：13594385937</p>
          </div>
        </div>
        <div class="excel-download">
          <div class="excel-left"></div>
          <div class="excel-right">
            <p class="header">送评单格式下载</p>
            <p class="download">
              <a
                @click="
                  downloads(
                    'http://bspg-sundry-01.yanbug.com/%E9%80%81%E8%AF%84%E5%8D%95.jpg'
                  )
                "
                >黑石评级送评单—下载</a
              >
            </p>
            <p class="info">备注：也可按照图片要求手写评级单，详细即可</p>
          </div>
        </div>
        <div class="address">
          <p>收评客服信息</p>
          <div class="address-info">
            <img src="@/assets/img/rating/code.png" />
          </div>
        </div>
      </div>
      <div v-show="currentMenu == 1" class="standard">
        <div
          v-for="item in ratingStandard"
          :key="item.name"
          class="standard-item"
        >
          <p class="standard-name">{{ item.name }}</p>
          <div class="standard-wrapper">
            <div
              v-for="(i, index) in item.standardList"
              :key="i.type"
              class="standard-tab"
              :class="{ row: index % 2 == 0 }"
            >
              <div class="standard-type">
                <p>{{ i.type }}</p>
              </div>
              <div class="standard-desc">
                <p
                  v-for="(p, index) in i.messageList"
                  :key="index"
                  class="standard-info"
                >
                  {{ p }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="currentMenu == 2" class="standard">
        <div v-for="item in feeStandard" :key="item.name" class="standard-item">
          <p class="standard-name">{{ item.name }}</p>
          <div class="standard-wrapper">
            <div
              v-for="(i, index) in item.standardList"
              :key="i.type"
              class="standard-tab"
              :class="{ row: index % 2 == 0 }"
            >
              <div class="standard-type" style="width: 50%">
                <p>{{ i.type }}</p>
              </div>
              <div class="standard-desc" style="text-align: center">
                <p
                  v-for="(p, index) in i.messageList"
                  :key="index"
                  class="standard-info"
                >
                  {{ p }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="currentMenu == 3" class="standard">
        <div class="right">
          <h2>黑石鉴定评级赔付协议</h2>
          <p>
            我司秉承高严格、高要求、高赔付的原则，为了更好的服务好广大的收藏朋友，我司对于黑石评级评级币有真假问题、经过我司复检确认评级失误后。我司将严格执行以下赔付标准。
            关于真假赔付标准：对每枚钱币进行一次性赔付，按照钱币赔付等级(赔付等级说明见下方表格）进行赔付。钱币在流通过程中产生的佣金和溢价均不赔付范围内
          </p>
          <p>
            关于评级版别信息赔付标准：我司只根据赔付等级进行赔付，无论钱币是否是名誉品或普通品。一律按照赔付等级进行赔付
          </p>
          <div
            v-for="item in ratingStandard1"
            :key="item.name"
            class="standard-item"
          >
            <div class="standard-wrapper">
              <div
                v-for="(i, index) in item.standardList"
                :key="i.type"
                class="standard-tab"
                :class="{ row: index % 2 == 0 }"
              >
                <div class="standard-type">
                  <p>{{ i.type }}</p>
                </div>
                <div class="standard-desc">
                  <p
                    v-for="(p, index) in i.messageList"
                    :key="index"
                    class="standard-info"
                  >
                    {{ p }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <p>
            不赔付条件<br />
            1.评级盒被私自砸开，钱币被清洗、篡改等改变钱币原本面貌的行为 <br />
            2.钱币后期因后期保存环境影响导致的变化。这类变化包括不限于：钱币返绣、长斑、变色、生裂、绣蚀等<br />
            3.由于标签输入的明显错误；（不予赔付，但是提供免费换盒服务）<br />
          </p>
        </div>
      </div>

      <div style="height: 1px"></div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "Home",
  setup() {
    const router = useRouter();
    const currentMenu = ref(0);
    const ratingStandard = [
      {
        name: "铜元评级标准",
        standardList: [
          {
            type: "MS",
            messageList: [
              "MS 70：钱币最佳状态，压痕深重，字口、马齿、地章、光泽皆为最佳；",
              "MS 69：字口、马齿、地章皆为最佳，关泽感弱一点；",
              "MS 68：未流通 ，有细微的划痕，一般要放大镜下才可见",
              "MS 67：未流通，瑕疵微小，",
              "MS 66：字口有轻微弱打 或者马齿有轻微弱打，地章皆为最佳；",
              "MS 65：字口有轻微弱打 或者马齿有轻微弱打，地章皆为最佳，光泽感弱一点",
              "MS 64压印较弱，有轻微的划痕；",
              "MS 63：字口压印有明显不完整，有轻微划痕；",
              "MS 62：字口压印有明显不完整，有明显细小划痕；",
              "MS 61：压印团不完整， 币面有肉眼可见的划痕",
            ],
          },
          {
            type: "AU",

            messageList: [
              "AU 58：所有的图案保存较完整，只有极小区域地方有磨损，马齿完整切清晰，地章无明显的坑",
              "AU 56：图案有极小区域地方有磨损，马齿小部分磨损",
              "AU 54：图案清晰，马齿较全，地章有自然磨损",
              "AU 52：图案清晰，有小于百分之20的缺失， ",
              "AU 50：图案完整，有自然的磨损，马齿有小于百分之20的缺失，地章有自然磨损",
            ],
          },
          {
            type: "XF",
            messageList: [
              "XF 47：图案和马齿保存大致完整，有小区域的自然磨损",
              "XF 45：图案较完整，有一定的使用磨损，马齿保存较完整",
              "XF 43：图案较完整，有自然的使用磨损，马齿不完整",
              "XF 40：图案较完整，有成小面积的磨损，马齿不完整",
            ],
          },
          {
            type: "VF",
            messageList: [
              "VF：30 图案留存百分之50，核心图案区可见",
              "VF：20 图案存留百分之30以下，能通过剩图案辨别产地以及面值",
              "VF：10 图案存留百分之20一下，几乎无法辨认版别",
            ],
          },
          {
            type: "真品",
            messageList: ["钱币缺陷、破损非常严重"],
          },
          {
            type: "缺陷说明",
            messageList: [
              "01（污染带绣面积大），02（药水清理及其他物理清理不当），03（修补），04（人为上锈或上 色），05（有不同程度的裂纹），06（后期人为修改，破损），07（铸造时留下的缺陷，非后期人 为），08（位移、叠压、阴阳、单面等错版币）",
            ],
          },
        ],
      },
      {
        name: "邮票评级标准",
        standardList: [
          {
            type: "100",
            messageList: ["完美印刷工艺，各个方面都无可挑剔"],
          },
          {
            type: "99",
            messageList: [
              "原胶绝品，与100相比，肉眼几乎没有差别，在高倍放大镜下能找到细微的瑕疵",
            ],
          },
          {
            type: "98",
            messageList: ["原胶绝品，印刷工艺，有轻微残留油墨印"],
          },
          {
            type: "97",
            messageList: ["原胶绝品，肉眼下有细微的瑕疵，比如印刷和油墨"],
          },
          {
            type: "96",
            messageList: ["票面基本没有触摸痕迹，齿孔数无差异无弱齿"],
          },
          {
            type: "95",
            messageList: ["邮票图案有极其轻微的位移"],
          },
          {
            type: "90",
            messageList: ["票面有轻微的触摸痕迹，有细小的弱齿，"],
          },
          {
            type: "85",
            messageList: [
              "有正常的流通痕迹，肉眼可见瑕疵，无折痕，背胶有轻微处理",
            ],
          },
          {
            type: "80",
            messageList: ["有脱胶，和轻微霉斑现象，边齿有瑕疵"],
          },
          {
            type: "70",
            messageList: ["有轻微的折痕，污渍和褪色现象"],
          },
          {
            type: "60",
            messageList: ["有明显的折痕或者邮戳，票面有严重褪色"],
          },
          {
            type: "50",
            messageList: ["有明显的折痕或者邮戳，使邮票看不清楚"],
          },
          {
            type: "真品",
            messageList: ["有大量的缺陷和折痕、图案磨损难以辨认"],
          },
        ],
      },
      {
        name: "古钱币评级标准",
        standardList: [
          {
            type: "美品",
            messageList: [
              "美品80-100字面完整，穿口规整，没有腐蚀绣缺陷，绣色均匀覆盖，没有明显的人为清理痕迹",
            ],
          },
          {
            type: "中品",
            messageList: [
              "中品40-80分字面和穿口保存较好，边道允许有轻微的磕碰",
            ],
          },
          {
            type: "差品",
            messageList: ["40分以下钱币有多方面的缺陷"],
          },
          {
            type: "瑕疵代码",
            messageList: [
              "01（污染带绣面积大），02（药水清理及其他物理清理不当），03（修补），04（人为上锈或上 色），05（有不同程度的裂纹），06（后期人为修改，破损），07（铸造时留下的缺陷，非后期人 为），08（位移、叠压、阴阳、单面等错版币）",
            ],
          },
        ],
      },
      {
        name: "纸钞评级标准",
        standardList: [
          {
            type: "70EPQ",
            messageList: [
              "完美状态，在高倍放大镜下，看不见任何的指纹，和其他痕迹。钱币制造方面无任何的瑕疵",
            ],
          },
          {
            type: "69EPQ",
            messageList: ["肉眼下几乎和70状态无任何去区别"],
          },
          {
            type: "68EPQ",
            messageList: ["有非常轻微的触摸痕迹，钱币制造方面无任何的瑕疵"],
          },
          {
            type: "67EPQ",
            messageList: ["裁剪和图案有微微的不居中"],
          },
          {
            type: "66EPQ",
            messageList: ["有稍多的触摸痕迹，裁剪和图案居中"],
          },
          {
            type: "65EPQ",
            messageList: ["纸面上肉眼可见有5处以下的轻微触摸痕迹"],
          },
          {
            type: "64",
            messageList: ["纸张挺直，有明显的触摸痕迹，图案有不居中现象"],
          },
          {
            type: "63",
            messageList: ["纸张挺直，图案部分有轻微磨平现象，居中不够完美"],
          },
          {
            type: "62",
            messageList: [
              "轻度触摸痕迹，纸张挺直无痕，油墨有轻微的褪色，居中度不够完美",
            ],
          },
          {
            type: "61",
            messageList: [
              "纸张挺直无痕，触摸痕迹明显。油墨有轻微的褪色，居中度不够完美",
            ],
          },
          {
            type: "60",
            messageList: ["有小的黄色霉点，轻微的褪色"],
          },
          {
            type: "58",
            messageList: ["纸面有轻微软折，但没有折痕，纸角不尖"],
          },
          {
            type: "55",
            messageList: ["纸角有折痕，有中度的触摸痕迹"],
          },
          {
            type: "53",
            messageList: ["有2条竖向折痕，有中度的触摸痕迹"],
          },
          {
            type: "50",
            messageList: ["折痕严重，触摸痕迹明显"],
          },
          {
            type: "45",
            messageList: ["有3处以上的折痕或处理痕迹，纸角有明显的磨损"],
          },
          {
            type: "40",
            messageList: ["折痕严重，整体票面保存完整"],
          },
          {
            type: "35",
            messageList: ["折痕较多，3条以上，纸币有明显的触摸痕迹"],
          },
          {
            type: "30",
            messageList: ["纸币有流通痕迹和污染痕迹"],
          },
          {
            type: "25",
            messageList: ["较多的流通和污染痕迹"],
          },
          {
            type: "20",
            messageList: ["中度流通痕迹，单纸张无缺"],
          },
          {
            type: "15",
            messageList: ["中度流通痕迹，单纸张无缺，纸张有裂口，角磨圆的现象"],
          },
          {
            type: "10",
            messageList: ["经过多次市场流通，有小的瑕疵"],
          },
          {
            type: "真品",
            messageList: ["已经经过市场严重流通，有多处的小瑕疵和缺陷"],
          },
          {
            type: "定义",
            messageList: [
              "EPQ：表示钱币的原始状态，纸币出厂后未经过任何的物理和化学的处理。只有65分以上才能标注EPQ。",
              "⭐：     代表纸币的珍惜程度，稀有的纸币和稀有的版别可以标注⭐，按照稀有度可标注⭐、⭐⭐和⭐⭐⭐。⭐⭐⭐最为稀少。",
            ],
          },
        ],
      },
    ];
    const feeStandard = [
      {
        name: "铜元收费标准",
        standardList: [
          {
            type: "估值",
            messageList: ["单价（元/枚）"],
          },
          {
            type: "1000以下",

            messageList: ["30"],
          },
          {
            type: "1000到5000以下",
            messageList: ["45"],
          },
          {
            type: "5000到2万以下",
            messageList: ["100"],
          },
          {
            type: "2万到5万以下",
            messageList: ["300"],
          },
          {
            type: "5万到10万以下",
            messageList: ["500"],
          },
          {
            type: "10万以上",
            messageList: ["估价的百分之一"],
          },
          {
            type: "直径超过40MM",
            messageList: ["加收20元评级费"],
          },
        ],
      },
      {
        name: "古钱币收费标准",
        standardList: [
          {
            type: "估值",
            messageList: ["单价（元/枚）"],
          },
          {
            type: "1000以下",

            messageList: ["30"],
          },
          {
            type: "1000到5000以下",
            messageList: ["45"],
          },
          {
            type: "5000到2万以下",
            messageList: ["100"],
          },
          {
            type: "2万到5万以下",
            messageList: ["300"],
          },
          {
            type: "5万到10万以下",
            messageList: ["500"],
          },
          {
            type: "10万以上",
            messageList: ["估价的百分之一"],
          },
          {
            type: "直径超过40MM",
            messageList: ["加收20元评级费"],
          },
        ],
      },
      {
        name: "邮票/纸币收费标准",
        standardList: [
          {
            type: "估值",
            messageList: ["单价（元/枚）"],
          },
          {
            type: "1000以下",

            messageList: ["30"],
          },
          {
            type: "1000到5000以下",
            messageList: ["45"],
          },
          {
            type: "5000到2万以下",
            messageList: ["100"],
          },
          {
            type: "2万到5万以下",
            messageList: ["300"],
          },
          {
            type: "5万到10万以下",
            messageList: ["500"],
          },
          {
            type: "10万以上",
            messageList: ["估价的百分之一"],
          },
          {
            type: "直径超过40MM",
            messageList: ["加收20元评级费"],
          },
        ],
      },
    ];
    const ratingStandard1 = [
      {
        standardList: [
          {
            type: "赔付等级",
            messageList: ["赔付金额"],
          },
          {
            type: "等级Ⅰ",

            messageList: ["按照钱币最近成交价进行赔偿"],
          },
          {
            type: "等级Ⅱ",

            messageList: ["50000"],
          },
          {
            type: "等级Ⅲ",

            messageList: ["10000"],
          },
          {
            type: "等级Ⅳ",

            messageList: ["5000"],
          },
          {
            type: "等级Ⅴ",

            messageList: ["2000"],
          },
          {
            type: "等级Ⅵ",

            messageList: ["1000"],
          },
          {
            type: "等级Ⅶ",

            messageList: ["500"],
          },
          {
            type: "等级Ⅷ",

            messageList: ["200"],
          },
          {
            type: "等级Ⅸ",

            messageList: ["100"],
          },
          {
            type: "等级Ⅹ",
            messageList: ["50"],
          },
        ],
      },
    ];
    const keyWord = ref("");
    const search = function () {
      if (keyWord.value.trim() != "") {
        router.push({ name: "appraisal", query: { id: keyWord.value } });
      }
    };
    /**
     * 下载图片
     * @param {string} imgsrc 图片地址
     */
    const downloadIamge = function (imgsrc) {
      // 新建图片对象
      let image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      // 图片加载
      image.onload = function () {
        // 新建 canvas标签
        let canvas = document.createElement("canvas");
        // 设置 canvas宽高
        canvas.width = image.width;
        canvas.height = image.height;
        // 添加 canvas画笔
        let context = canvas.getContext("2d");
        // 绘制图片
        context.drawImage(image, 0, 0, image.width, image.height);
        // 得到图片的 base64 编码
        let url = canvas.toDataURL("image/png");
        // 新建 a标签
        let a = document.createElement("a");
        // 将图片的 base64 编码，设置为 a标签的地址
        a.href = url;
        a.setAttribute("download", "送评单.png");
        // 触发点击事件
        a.click();
      };
      // 将图片地址 设置为 传入的参数 imgsrc
      image.src = imgsrc;
    };

    /**
     * 下载方法
     * @param {string} filepath 文件地址
     */
    const downloads = function (filepath) {
      let time = new Date().getTime();
      downloadIamge(filepath + "?tamp=" + time);
    };

    return {
      currentMenu,
      ratingStandard,
      ratingStandard1,
      feeStandard,
      keyWord,
      search,
      downloads,
    };
  },
};
</script>

<style lang="less" scoped>
#main {
  background-color: #f7f2ef;
  z-index: 1;
  position: relative;
  .banner {
    position: relative;
    padding-bottom: 25.885%;
    height: 0px;
    img {
      position: relative;
      top: 0px;
      width: 100%;
      display: block;
    }
    .search-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 20;
      span {
        font-size: 40px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        display: inline-block;
        margin-bottom: 20px;
      }
      .search-bar {
        display: flex;
        position: relative;
        img {
          position: absolute;
          width: 20px;
          height: 20px;
          left: 35px;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        input {
          padding-left: 70px;
          width: 700px;
          height: 60px;
          box-sizing: border-box;

          border: none;
          outline: none;
          font-size: 26px;
          &::before {
            content: "";
            width: 70px;
            height: 50px;
            display: block;
            background: url("@/assets/img/rating/search.png") no-repeat center;
          }
          ::-webkit-input-placeholder {
            /* WebKit browsers */
            color: #999;
            font-size: 16px;
          }
          ::-webkit-input-placeholder,
          textarea::-webkit-input-placeholder {
            color: #666;
            font-size: 16px;
          }
        }
        .search {
          width: 100px;
          height: 60px;
          background: #c19b76;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          text-align: center;
          line-height: 60px;
          user-select: none;
          cursor: pointer;
          color: #ffffff;
        }
      }
    }
  }
  .w {
    .wrapper {
      margin-bottom: 60px;
      .list-bar {
        margin-top: -80px;

        z-index: 100;
        height: 134px;
        position: relative;
        background: url("@/assets/img/rating/wave.png") no-repeat center;
        background-size: 100% 100%;
        ul {
          display: flex;
          justify-content: space-between;
          li {
            line-height: 134px;
            font-size: 30px;
            font-weight: bold;
            color: #2a2a2a;
            width: 25%;
            text-align: center;
            cursor: pointer;
            position: relative;
            &.active {
              color: #a16831;
              &::before {
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                background-color: #a16831;
                position: absolute;
                top: 80%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
              }
            }
          }
        }
      }
    }
    .address {
      padding: 20px 0px;
      background: #fff;
      margin-bottom: 60px;
      > p {
        font-size: 40px;
        text-align: center;
        font-weight: bold;
        color: #2a2a2a;
        padding: 40px 0px 20px;
        border-bottom: 1px solid rgba(42, 42, 42, 0.1);
      }
      .address-info {
        padding: 40px 0px;
        text-align: center;
        p {
          text-align: center;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          margin: 20px 0px;
          color: #666666;
        }
        img {
          width: 250px;
        }
      }
    }
    .excel-download {
      display: flex;
      margin-bottom: 60px;
      .excel-left {
        width: 50%;
        height: 450px;
        background: url("@/assets/img/rating/excel-left.png") no-repeat center;
        background-size: cover;
      }
      .excel-right {
        width: 50%;
        height: 450px;
        background: url("@/assets/img/rating/excel-right.png") no-repeat center;
        background-size: cover;
        position: relative;

        .header {
          font-size: 44px;
          font-weight: bold;
          color: #ffffff;
          position: absolute;
          top: 20px;
          left: 40px;
        }
        .download {
          cursor: pointer;
          font-size: 28px;
          position: absolute;
          bottom: 270px;
          left: 40px;
          font-weight: 400;
          color: #c19b76;
        }
        .info {
          font-size: 20px;
          position: absolute;
          bottom: 20px;
          left: 40px;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    .standard {
      .right {
        flex: 1;
        background-color: #fff;
        padding: 40px;
        h2 {
          font-size: 44px;
          font-weight: bold;
          color: #2a2a2a;
        }
        p {
          margin: 20px 0px;
          font-size: 24px;
          line-height: 1.5;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
        }
      }
      .standard-item {
        margin-bottom: 40px;
        .standard-name {
          font-size: 44px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #b7824e;
          margin-bottom: 20px;
        }
        .standard-wrapper {
          border: 2px solid #e7d2be;
          .standard-tab {
            display: flex;
            background: #f7f2ef;
            &.row {
              background: #f1e8df;
            }
            .standard-type {
              width: 250px;
              font-size: 30px;
              border-right: 1px solid #e7d2be;
              font-family: Source Han Sans CN;
              font-weight: bold;
              text-align: center;
              color: #2a2a2a;
              display: flex;
              align-items: center;
              text-align: center;
              p {
                flex: 1;
              }
            }
            .standard-desc {
              padding: 40px;
              font-size: 22px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #2a2a2a;
              flex: 1;
              p {
                line-height: 30px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

