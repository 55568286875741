<template>
  <div id="main">
    <div class="banner">
      <img src="@/assets/img/appraisal/banner.png" />
    </div>
    <div class="w">
      <div class="wrapper">
        <div class="left">
          <div>服务协议</div>
          <div>赔付等级说明</div>
        </div>
        <div class="right">
          <h2>黑石鉴定评级赔付协议</h2>
          <p>
            我司秉承高严格、高要求、高赔付的原则，为了更好的服务好广大的收藏朋友，我司对于黑石评级评级币有真假问题、经过我司复检确认评级失误后。我司将严格执行以下赔付标准。
            关于真假赔付标准：对每枚钱币进行一次性赔付，按照钱币赔付等级(赔付等级说明见下方表格）进行赔付。钱币在流通过程中产生的佣金和溢价均不赔付范围内
          </p>
          <p>
            关于评级版别信息赔付标准：我司只根据赔付等级进行赔付，无论钱币是否是名誉品或普通品。一律按照赔付等级进行赔付
          </p>
          <div
            v-for="item in ratingStandard"
            :key="item.name"
            class="standard-item"
          >
            <div class="standard-wrapper">
              <div
                v-for="(i, index) in item.standardList"
                :key="i.type"
                class="standard-tab"
                :class="{ row: index % 2 == 0 }"
              >
                <div class="standard-type">
                  <p>{{ i.type }}</p>
                </div>
                <div class="standard-desc">
                  <p
                    v-for="(p, index) in i.messageList"
                    :key="index"
                    class="standard-info"
                  >
                    {{ p }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <p>
            不赔付条件<br>
            1.评级盒被私自砸开，钱币被清洗、篡改等改变钱币原本面貌的行为 <br>
            2.钱币后期因后期保存环境影响导致的变化。这类变化包括不限于：钱币返绣、长斑、变色、生裂、绣蚀等<br>
            3.由于标签输入的明显错误；（不予赔付，但是提供免费换盒服务）<br>
          </p>
       
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  setup() {
    const ratingStandard = [
      {
        standardList: [
          {
            type: "赔付等级",
            messageList: ["赔付金额"],
          },
          {
            type: "等级Ⅰ",

            messageList: ["按照钱币最近成交价进行赔偿"],
          },
          {
            type: "等级Ⅱ",

            messageList: ["50000"],
          },
          {
            type: "等级Ⅲ",

            messageList: ["10000"],
          },
          {
            type: "等级Ⅳ",

            messageList: ["5000"],
          },
          {
            type: "等级Ⅴ",

            messageList: ["2000"],
          },
          {
            type: "等级Ⅵ",

            messageList: ["1000"],
          },
          {
            type: "等级Ⅶ",

            messageList: ["500"],
          },
          {
            type: "等级Ⅷ",

            messageList: ["200"],
          },
          {
            type: "等级Ⅸ",

            messageList: ["100"],
          },
          {
            type: "等级Ⅹ",
            messageList: ["50"],
          },
        ],
      },
    ];
    return { ratingStandard };
  },
};
</script>

<style lang="less" scoped>
#main {
    
  background-color: #f7f2ef;
  z-index: 1;
  position: relative;
  .banner {
    position: relative;
    padding-bottom: 13.64583%;
    height: 0px;
    img {
      position: relative;
      top: 0px;
      width: 100%;
      display: block;
    }
  }
  .w {
    padding: 40px;

    .wrapper {
      display: flex;
      .left {
        height: fit-content;
        background: #fff;
        border: 1px solid #e7d2be;
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2a2a2a;
        width: 250px;
        margin-right: 40px;
        > div {
          text-align: center;
          padding: 30px;
          border-bottom: 1px solid #2a2a2a1a;
          &:last-of-type {
            border-bottom: none;
          }
        }
      }
      .right {
        flex: 1;
        background-color: #fff;
        padding: 40px;
        h2 {
          font-size: 44px;
          font-weight: bold;
          color: #2a2a2a;
        }
        p {
          margin: 20px 0px;
          font-size: 24px;
          line-height: 1.5;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
        }
        .standard-item {
          .standard-name {
            font-size: 44px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #b7824e;
            margin-bottom: 20px;
          }
          .standard-wrapper {
            border: 2px solid #e7d2be;
            .standard-tab {
              display: flex;
              background: #f7f2ef;
              &.row {
                background: #f1e8df;
              }
              .standard-type {
                width: 250px;
                font-size: 30px;
                border-right: 1px solid #e7d2be;
                font-family: Source Han Sans CN;

                display: flex;
                align-items: center;
                text-align: center;
                p {
                  font-weight: bold;
                  text-align: center;
                  color: #2a2a2a;
                  flex: 1;
                }
              }
              .standard-desc {
                text-align: center;
                font-size: 22px;
                font-family: Microsoft YaHei;
                font-weight: 400;

                flex: 1;
                p {
                  color: #2a2a2a;
                  line-height: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

