<template>
  <div id="main">
    <div class="banner">
      <img src="@/assets/img/appraisal/banner.png" />
    </div>
    <div class="w">
      <div class="wrapper">
        <div class="appraisal">
          <p class="title_en">IDENTIFICATION OF THE QUERY</p>
          <p class="title_zh">鉴定查询</p>
          <div class="search-bar">
            <img src="@/assets/img/rating/search.png" />
            <input
              type="text"
              v-model="keyWord"
              placeholder="请输入评级编号进行查询"
              @keyup.enter="search"
            />
            <div class="search" @click="search">搜索</div>
          </div>
          <div class="no-result" v-if="notFindWord != ''">
            {{ notFindWord }}
          </div>
        </div>
      </div>
      <div class="result" v-if="result">
        <p>查询结果</p>
        <div class="result-wrapper">
          <div class="img-wrapper">
            <div
              class="result-img"
              v-for="(img, index) in result.gradingImageList"
              :key="index"
            >
              <n-image :src="img" />
            </div>
            <div class="result-img" style="height: 0px"></div>
          </div>

          <div class="result-info">
            <div class="row" v-if="result.categoryCode">
              <div><p>品类</p></div>
              <div>
                <p>{{ typeMap[result.categoryCode] }}</p>
              </div>
            </div>
            <div class="row" v-if="result.id">
              <div><p>评级编号</p></div>
              <div>
                <p>{{ result.id }}</p>
              </div>
            </div>
            <div class="row" v-if="result.artifactInfo.edition.name">
              <div><p>名称</p></div>
              <div>
                <p>{{ result.artifactInfo.edition.name }}</p>
              </div>
            </div>
            <div class="row" v-if="result.artifactInfo.edition.issuer">
              <div><p>发行机构</p></div>
              <div>
                <p>{{ result.artifactInfo.edition.issuer }}</p>
              </div>
            </div>
            <div class="row" v-if="result.artifactInfo.edition.style">
              <div><p>版式</p></div>
              <div>
                <p>{{ result.artifactInfo.edition.style }}</p>
              </div>
            </div>
            <div class="row" v-if="result.artifactInfo.edition.stampNumber">
              <div><p>志号</p></div>
              <div>
                <p>{{ result.artifactInfo.edition.stampNumber }}</p>
              </div>
            </div>

            <div class="row" v-if="result.artifactInfo.gradingScore.score">
              <div><p>分数</p></div>
              <div>
                <p>{{ result.artifactInfo.gradingScore.score }}</p>
              </div>
            </div>
            <div class="row" v-if="result.artifactInfo.edition.year">
              <div><p>年份</p></div>
              <div>
                <p>{{ result.artifactInfo.edition.year }}</p>
              </div>
            </div>
            <div class="row" v-if="result.artifactInfo.edition.value">
              <div><p>面值</p></div>
              <div>
                <p>{{ result.artifactInfo.edition.value }}</p>
              </div>
            </div>
            <div class="row" v-if="result.artifactInfo.size">
              <div><p>规格（尺寸、重量）</p></div>
              <div>
                <p>
                  {{
                    `直径${result.artifactInfo.size.diameter}、厚度${result.artifactInfo.size.thickness}、重量${result.artifactInfo.size.weight}`
                  }}
                </p>
              </div>
            </div>
            <div class="row" v-if="result.compensationLevel">
              <div><p>赔付等级</p></div>
              <div>
                <p>{{ levelMap[result.compensationLevel] }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { artInfo } from "@/api/index";
import { ref, defineComponent } from "vue";
import { NImage } from "naive-ui";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "Home",
  components: {
    NImage,
  },
  setup() {
    const keyWord = ref("");
    const notFindWord = ref("");
    const result = ref(null);
    const typeMap = {
      1: "铜币",
      2: "古钱币",
      3: "纸币",
      4: "邮票",
    };
    const levelMap = {
      1: "Ⅰ",
      2: "Ⅱ",
      3: "Ⅲ",
      4: "Ⅳ",
      5: "Ⅴ",
      6: "Ⅵ",
      7: "Ⅶ",
      8: "Ⅷ",
      9: "Ⅸ",
      10: "Ⅹ",
    };
    const search = function () {
      artInfo(keyWord.value).then((response) => {
        result.value = response.data.data;
        notFindWord.value = "";
        if (result.value == null) {
          notFindWord.value = '未查询到相关信息';
        }
      });
    };
    const route = useRoute();
    if (route.query.id) {
      keyWord.value = route.query.id;
      search();
    }
    return { typeMap, notFindWord, levelMap, keyWord, result, search };
  },
});
</script>

<style lang="less" scoped>
#main {
  background-color: #f7f2ef;
  z-index: 1;
  position: relative;
  .banner {
    position: relative;
    padding-bottom: 13.64583%;

    height: 0px;
    img {
      position: relative;
      top: 0px;
      width: 100%;
      display: block;
    }
  }
  .w {
    padding: 40px;
    box-sizing: border-box;
    .wrapper {
      padding: 40px;

      background: #fff;

      .appraisal {
        .title_en {
          font-size: 19px;
          font-weight: 500;
          color: #222222;
          line-height: 19px;
          line-height: 40px;
        }
        .title_zh {
          font-size: 44px;
          font-weight: bold;
          color: #b7824e;
        }
        .search-bar {
          display: flex;
          position: relative;
          margin-top: 40px;
          img {
            position: absolute;
            width: 20px;
            height: 20px;
            left: 35px;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          input {
            padding-left: 70px;
            width: 700px;
            height: 60px;
            box-sizing: border-box;

            border: none;
            outline: none;
            font-size: 26px;
            background: #f7f2ef;
            &::before {
              content: "";
              width: 70px;
              height: 50px;
              display: block;
              background: url("@/assets/img/rating/search.png") no-repeat center;
            }
            ::-webkit-input-placeholder {
              /* WebKit browsers */
              color: #999;
              font-size: 16px;
            }
            ::-webkit-input-placeholder,
            textarea::-webkit-input-placeholder {
              color: #666;
              font-size: 16px;
            }
          }
          .search {
            width: 100px;
            height: 60px;
            background: #c19b76;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            text-align: center;
            line-height: 60px;
            user-select: none;
            cursor: pointer;
            color: #ffffff;
          }
        }
        .no-result {
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #e92a1b;
          text-align: center;
          margin-top: 40px;
        }
      }
    }
    .result {
      margin-top: 40px;
      > p {
        margin-bottom: 20px;
        font-size: 36px;
        font-weight: bold;
        color: #2a2a2a;
      }
      .result-wrapper {
        .img-wrapper {
          display: flex;
          margin-bottom: 20px;
          justify-content: space-between;
          flex-wrap: wrap;
          .result-img {
            width: 350px;

            /deep/img {
              width: 350px;
              height: 300px;
            }
          }
        }

        .result-info {
          flex: 1;
          background: #fff;
          display: flex;
          flex-direction: column;
          .row {
            flex: 1;
            display: flex;

            > div {
              &:first-of-type {
                border-right: 1px solid #f1f1f1;
              }
              flex: 1;
              align-items: center;
              text-align: center;
              font-size: 20px;
              font-weight: 400;
              color: #222222;
              border-bottom: 1px solid #f1f1f1;
              display: flex;
              align-items: center;
              p {
                width: 100%;
                padding: 20px 0px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

